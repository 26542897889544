<template>
  <div id="contents" class="contents hot_deal page">
    <div class="content_head">
      <!-- s: 페이지 타이틀 -->
      <div class="page_ttl_wrap has_btn">
        <h2 class="page_ttl">우리매장핫딜</h2>
        <div class="btn_group">
          <span>최근 발행일시 {{recentPublishTime ? moment(recentPublishTime).format('YYYY.MM.DD HH:mm:ss') : '없음'}}</span>
          <!-- Dev: 변경사항이 없을시 비활성화 -->
          <button type="button" class="btn strong2 w_m h_l" @click="publish">발행하기</button>
        </div>
      </div>
    </div>
    <!-- e: 페이지 타이틀 -->

    <!-- s: content_body -->
    <div class="content_body">
      <!-- s : link_sec -->
      <div class="link_sec">
        <!-- Dev: 오류 텍스트 노출 시 input_area에 is_error 클래스 추가-->
        <div class="input_area inline">
          <label></label>
          <div class="input_box">
            <input type="text" placeholder="Link URL을 입력해주세요." v-model="iframeUrl">
          </div>
          <!-- Dev : 확인 버튼 클릭시 url의 value로 미리보기영역(.iframe_preview_area)에 주소값을 변경해준다. -->
          <div class="btn_area">
            <button @click="previewIframe" class="btn strong w_ss h_l">확인</button>
          </div>
          <small class="error_txt" style="display: none">URL Link를 입력 해 주세요.</small>
        </div>
        <div class="info_guide_area">
          <p class="info_guide_txt"><img src="../../../../assets/img/common/ic_info.png" alt="">Link URL이
            iframe을 지원하지
            않을 경우 미리보기가 되지 않을 수 있습니다.</p>
        </div>
      </div>
      <!-- e : link_sec -->
      <!-- s: preview_sec -->
      <div class="preview_sec">
        <div class="preview_wrap">
          <div class="upload_guide" role="button">
            <div class="tooltip_area">
              <img src="../../../../assets/img/common/tooltip.png" alt="">
              <p>파일 업로드 가이드</p>
            </div>
            <div class="layer_info">
              <div class="info_area" v-for="(item, i) in tooltip" :key="'item' + i">
                <div class="ttl">{{item.ttl}}</div>
                <div class="cnt">
                  <p>{{item.file}}</p>
                  <p>{{item.memory}}</p>
                  <p>{{item.display}}</p>
                </div>
              </div>
              <p class="info_txt">※ 파일명은 영어로 등록해주세요.</p>
            </div>
          </div>
          <div class="preview_area">
            <!-- Dev : url의 value값 연결 -->
            <iframe class="iframe_preview_area" :src="previewUrl">
            </iframe>
            <div class="iframe_preview_qr_wrap">
              <div class="iframe_preview_qr_area">
                <!-- Dev: 등록된 QR 이미지 qr_box에 background-image로 적용-->
                <div class="qr_box"
                     :class="{'has_img' : imgFile}"
                     :style="`background-image: url(${getFile(imgFile)})`">
                  <!-- Dev s: 등록된 파일 없을 경우 -->
                  <button v-if="!imgFile">
                    qr 추가
                    <input type="file" accept="image/gif,image/jpeg,image/png" @change="onFileChange($event)">
                  </button>
                  <!-- Dev e: 등록된 파일 없을 경우 -->
                </div>
              </div>
              <p class="qr_txt">모바일 주문 바로가기</p>
              <!--  Dev: 파일이 있을 경우에만 hover_btn_area 출력 -->
              <template v-if="imgFile">
                <div class="hover_btn_area">
                  <!--  Dev: 파일 수정 버튼 클릭시 파일첨부 호출 -->
                  <button class="modify_btn">
                    <img src="../../../../assets/img/common/video_modify.png" alt="">
                    <input type="file" accept="image/gif,image/jpeg,image/png" @change="onFileChange($event)">
                  </button>
                  <!--  Dev: 파일 삭제 버튼 클릭시 첨부 된 파일 삭제 -->
                  <button @click="deleteImage" class="delete_btn">삭제</button>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
      <!-- e: preview_sec -->
    </div>
    <!-- e: content_body -->
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "new-index",
  layout: 'new-admin',
  data() {
    return {
      moment: moment,
      submenu_hotdeal_publish_key: 'publish/submenu/hotdeal',
      submenu_hotdeal_root_key: 'submenu/hotdeal',
      recentPublishTime: '',
      iframeUrl: 'about:blank',
      previewUrl: 'about:blank',
      imgFile: null,
      tooltip: [
        {
          ttl: 'QR 이미지',
          file: 'PNG',
          memory: '용량 2MB 이하',
          display: '해상도 90*90',
        },
      ],
      canLeaveSite: true,
      isUploading: false,
    }
  },
  methods: {
    async publish() {
      const time = this.timeToString(moment());
      await this.$firebase.database().ref(this.submenu_hotdeal_publish_key).child('lastPublishDateTime').set(time);
      this.toastSuccess('발행 완료.');
    },
    previewIframe() {
      this.previewUrl = this.iframeUrl;
      this.$firebase.database().ref(this.submenu_hotdeal_root_key).child('previewUrl').set(this.previewUrl);
    },
    async deleteImage() {
      // 2022-01-25 파일 삭제 임시 주석
      //await this.deleteFolderContents(this.submenu_hotdeal_root_key);
      this.$firebase.database().ref(this.submenu_hotdeal_root_key).child('imgFile').remove().then(async () => {
        this.imgFile = null;
      });
      this.canLeaveSite = false;
    },
    async onFileChange(e) {
      if (this.isUploading) {
        return;
      }

      let files = e.target.files || e.dataTransfer.files;
      if (!files.length)
        return;
      let file = files[0];

      const isImage = this.isImage(file);
      const maxImageSize = 2 * 1024 * 1024;
      if (isImage) {
        if (maxImageSize <= file.size) {
          this.toastDanger('이미지 용량은 2MB 이하로 등록해주세요.');
          return;
        }
      } else {
        this.toastDanger('PNG 이미지 파일만 가능합니다.');
        return;
      }

      this.isUploading = true;
      this.loadingOverlay(async (loader) => {
        this.imgFile = file;
        let downloadUrl = await this.upload(this.submenu_hotdeal_root_key, file, 0, 1);
        await this.$firebase.database().ref(this.submenu_hotdeal_root_key).child('imgFile').set(downloadUrl);
        this.canLeaveSite = false;

        this.isUploading = false;
        loader.hide();
      });
    },
    async upload(hash, image, current, total) {
      let storageRef = this.$firebase.storage().ref();
      let imageRef = storageRef.child(hash).child(image.name);
      let uploadTask = imageRef.put(image);
      return new Promise(((resolve, reject) => {
        uploadTask.on('state_changed', (snapshot) => {
          let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          const gage = (Math.floor(100 / total) * current) + (Math.floor(progress) / total);
          this.$Progress.set(gage);
          //console.log('Upload is ' + progress + '% done');
          switch (snapshot.state) {
            case this.$firebase.storage.TaskState.PAUSED: // or 'paused'
              //console.log('Upload is paused');
              break;
            case this.$firebase.storage.TaskState.RUNNING: // or 'running'
              //console.log('Upload is running');
              break;
          }
        }, (error) => {
          console.error('[ERROR] state_changed', error);
          reject(error);
        }, () => {
          // 완료 다운로드 예시
          resolve(uploadTask.snapshot.ref.getDownloadURL());
        });
      }));
    },
    deleteFolderContents(path) {
      const ref = this.$firebase.storage().ref(path);
      return ref.listAll()
          .then(dir => {
            dir.items.forEach(fileRef => {
              this.deleteFile(ref.fullPath, fileRef.name);
            });
            dir.prefixes.forEach(folderRef => {
              this.deleteFolderContents(folderRef.fullPath);
            })
          })
          .catch(error => {
            console.log(error);
          });
    },
    deleteFile(pathToFile, fileName) {
      const ref = this.$firebase.storage().ref(pathToFile);
      const childRef = ref.child(fileName);
      childRef.delete();
      this.canLeaveSite = false;
    },
  },
  created() {
    this.$firebase.database().ref(this.submenu_hotdeal_publish_key).on('value', (sn) => {
      const publish = sn.val() || {};
      if (publish) {
        this.recentPublishTime = publish.lastPublishDateTime;
      }
    });
    this.$firebase.database().ref(this.submenu_hotdeal_root_key).once('value', (sn) => {
      const submenu = sn.val() || {};
      if (submenu) {
        this.imgFile = submenu.imgFile;
        this.previewUrl = submenu.previewUrl;
        this.iframeUrl = submenu.previewUrl;
      }
    })
  },
  mounted() {
    window.addEventListener('beforeunload', this.unLoadEvent);
  },
  beforeUnmount() {
    window.removeEventListener('beforeunload', this.unLoadEvent);
  },
  beforeRouteLeave(to, from, next) {
    if (this.canLeaveSite) next();
    else if (confirm('이 사이트에서 나가시겠습니까?\n변경사항이 저장되지 않을 수 있습니다.')) {
      next();
    }
  }
}
</script>

<style scoped>

</style>
